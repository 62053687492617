.lozenge {
  background: #DFE1E6;
  border: 1px solid #DFE1E6;
  color: #253858;

  border-radius: 3px;
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  line-height: 99%;
  margin: 0;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  &.muted {
    background-color: #FFFFFF !important;
    border-color: #C1C7D0 !important;
    color: #42526E !important;
  }
  &.lozenge-pending{
    background-color: #854d0e !important;
    border-color: #854d0e !important;
    color: #FFFFFF !important;

    &.muted {
      background-color: #fef9c3 !important;
      border-color: #fef9c3 !important;
      color: #854d0e !important;
    }
  }
  &.lozenge-success{
    background-color: #00875A !important;
    border-color: #00875A !important;
    color: #FFFFFF !important;

    &.muted {
      background-color: #FFFFFF !important;
      border-color: #ABF5D1 !important;
      color: #00875A !important;
    }
  }
  &.lozenge-error{
    background-color: #DE350B !important;
    border-color: #DE350B !important;
    color: #FFFFFF !important;

    &.muted {
      background-color: #FFFFFF !important;
      border-color: #FFBDAD !important;
      color: #DE350B !important;
    }
  }
  &.lozenge-current{
    background-color: #B3D4FF !important;
    border-color: #B3D4FF !important;
    color: #0052CC !important;

    &.muted {
      background-color: #FFFFFF !important;
      border-color: #B3D4FF !important;
      color: #0052CC !important;
    }
  }
  &.lozenge-new{
    background-color: #5243AA !important;
    border-color: #5243AA !important;
    color: #FFFFFF !important;

    &.muted {
      background-color: #FFFFFF !important;
      border-color: #C0B6F2 !important;
      color: #5243AA !important;
    }
  }
  &.lozenge-ended{
    background-color: rgb(254, 243, 199) !important;
    border-color: rgb(120, 53, 15) !important;
    color: rgb(120, 53, 15) !important;

    //&.muted {
    //  background-color: #FFFFFF !important;
    //  border-color: #C0B6F2 !important;
    //  color: #5243AA !important;
    //}
  }
  &.lozenge-moved{
    background-color: #FFAB00 !important;
    border-color: #FFAB00 !important;
    color: #253858 !important;

    &.muted {
      background-color: #FFFFFF !important;
      border-color: #FFF0B3 !important;
      color: #42526E !important;
    }
  }
}

#cover-sub-name .lozenge {
  box-shadow: 2px 3px 6px #1a1a1a;
  text-shadow: none;
  top: -3px;
  display: inline-block;
  position: relative;
}
