.post {
  &.hasEvent {

  }

  &.isSticky {

  }

  .post-header {
    @include flex-row(center, stretch);

    .post-header-avatars {
      @include flex-row(center, center);
      padding-left: 16px;
      padding-right: 16px;
      min-width: 102px;
      min-height: 64px;

      .avatar-link {
        .avatar:hover {
          box-shadow: 0 0 8px 3px rgba(102,175,233,0.6);
        }
      }

      .avatar {
        $avatarSize: 54px;
        @include avatar($avatarSize);

        &.user-avatar {
          margin-left: -18px;
        }
      }
    }
    .post-header-content {
      flex: 1;
      @include font-raleway;

      .post-event-subject {
        font-weight: 700;
        font-size: 22px;
        color: $medBlue;
        margin-bottom: 6px;
      }
      .post-subject {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 6px;
      }
      .post-subtext {
        font-size: 12px;

        .post-event-times {
          margin-top: 6px;
        }
      }
    }

    .post-header-controls {
      flex: 0;
      display: flex;

      .post-header-control {
        flex: 1;
        margin-left: 8px;
        i {
          font-size: 16px;
          color: rgba($baseBlack, 0.6);
          vertical-align: text-bottom;
          transition: color 150ms;
          cursor: pointer;
          &:hover {
            color: rgba($baseBlack, 1);
          }
        }
      }
    }

    .post-header-context {
      display: flex;
      align-self: normal;

      .post-header-context-item {
        border: solid transparent 1px;
        border-radius: 3px;
        max-height: 18px;
        max-width: 18px;
        text-align: center;
        padding: 4px;
        width: 18px;
        font-size: 18px;
        position: relative;
        color: $medBlue;

        a {
          color: $medBlue;
        }
      }

      .post-header-context-btn {
        .post-header-context-menu {
          display: none;
          width: 180px;
          position: absolute;
          top: 9px;
          right: 9px;
          background: white;
          border: solid rgba($baseBlack, 0.24) 1px;
          text-align: left;
          padding: 12px;
          border-radius: 4px;
          box-shadow: 0 1px 4px rgba($baseBlack, 0.24);
          font-size: 16px;

          .post-header-context-menu-item {
            margin: $spacing-small;
            color: $baseBlack;
            a {
              text-decoration: none;
              color: $baseBlack;
            }
          }
        }

        &:hover {
          cursor: pointer;
          border: solid $medBlue 1px;

          .post-header-context-menu {
            display: block;
          }
        }
      }
    }
  }

  //.post-media-container {
  //  width: 100%;
  //
  //  .post-media {
  //    height: 100%;
  //    width: 100%;
  //    padding-bottom: 56.25%;
  //    position: relative;
  //
  //    .post-media-image {
  //      position: absolute;
  //      top: 0;
  //      bottom: 0;
  //      left: 0;
  //      right: 0;
  //      background-position: center;
  //      background-size: cover;
  //    }
  //  }
  //
  //  video {
  //    width: 100%;
  //  }
  //
  //  .post-media-video-playbtn {
  //    margin: auto;
  //    position: absolute;
  //    height: 128px;
  //    width: 128px;
  //    font-size: 128px;
  //    top: 0; left: 0; bottom: 0; right: 0;
  //    color: rgba(0,0,0,0.75);
  //  }
  //}

  .post-body {
    padding-left: 54px;
    padding-right: 54px;
    line-height: 23px;
    margin-bottom: 16px;
    margin-top: 16px;

    a {
      color: $baseBlue;
      text-decoration: underline;
      word-break: break-all;
    }
  }

  .post-event-body {
    color: #fff;
    padding: 8px;
    padding-left: 54px;
    padding-right: 54px;
    line-height: 23px;
    margin-bottom: 16px;

    .post-event-details-btn {
      background: $baseBlue;
      border: solid 2px $baseWhite;
      width: 100%;
      border-radius: 3px;
    }
  }

  .post-media-body {
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: 32px;
    position: relative;

    .post-body {
      margin-bottom: 0px !important;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0,0,0,.75);
      padding: 32px;
      color: #ffffff;
    }
  }

  .post-footer {
    @include flex-row(flex-start, stretch);
    font-size: 12px;
    //color: rgba($baseBlack, 0.6);
    margin-left: 16px;
    margin-right: 16px;

    .post-footer-details {
      flex: 1;
      padding-left: 16px;
    }
    .post-footer-controls {
      flex: 1;
      text-align: right;
      margin-right: 16px;
      .post-footer-control {
        display: inline-block;
        margin-left: 16px;
        i {
          font-size: 22px;
          color: rgba($baseBlack, 0.4);
          vertical-align: text-bottom;

          &.active {
            color: rgba($baseBlue, 1);
          }
        }
        .fa-comment-alt {
          vertical-align: middle;
        }
        &:hover {
          i {
            color: rgba($baseBlack, 0.8);
          }
        }
      }

      .actn-post-unhide,
      .actn-post-hide,
      .actn-post-like {
        cursor: pointer;
      }
    }
  }

  .post-organization,
  .post-author {
    color: $baseBlue;
    font-weight: 700;
  }

}

#post-create-form-container ,
#event-create-form-container {
  .post-create-form-type {
    display: flex;
    background-color: $baseBlue;
    .post-create-type {
      background-color: $baseBlue;
      flex-basis: 64px;
      flex-grow: 0;
      padding: 12px 16px;
      position: relative;
      color: $baseWhite;
      font-weight: bold;
      font-size: 16px;
      border-right: solid 1px white;
      cursor: pointer;
      transition: background-color 150ms;
      &:hover{
        background-color: $medBlue;
      }

      &.active {
        &::before {
          display: block;
          width: 14px;
          height: 14px;
          position: absolute;
          background-color: white;
          border: solid 1px  $baseBlue;
          content: ' ';
          transform: rotate(45deg);
          margin: auto;
          top: 36px;
          left: 0;
          right: 0;
          -webkit-clip-path: polygon(76% 0, 0 76%, 0 0);
          clip-path: polygon(76% 0, 0 76%, 0 0);
        }
      }
    }
  }

  .post-create-form-options {
    display: flex;
    background-color: $medBlue;
    .post-create-form-option {
      background-color: $medBlue;
      flex-grow: 0;
      padding: 8px 12px;
      position: relative;
      color: #ffffff;
      font-weight: bold;
      font-size: 14px;
      border-right: solid 1px white;
      border-bottom: solid 1px $medBlue;
      border-left: solid 1px $medBlue;
      cursor: pointer;
      transition: background-color 150ms, color 150ms;
      &.active,
      &:hover {
        background-color: $baseWhite;
        color: $baseBlue;

      }
    }
  }

  #post-create-post-main ,
#event-create-post-main {
    border: solid 1px $baseBlue;
  }

  #post-create-form-input-subject ,
#event-create-form-input-subject {
    border: none;
    width: 100%;
    margin-bottom: -1px;
    padding: 8px;
    box-sizing: border-box;
  }

  #post-create-form-input-body ,
#event-create-form-input-body {
    border: none;
    border-top: solid 1px $medBlue;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }

  #post-option-event-schedule-container ,
#event-option-event-schedule-container {
    border-top: dashed 1px $medBlue;
    background-color: rgba($medBlue, 0.15);
    padding: 36px 12px 12px;
  }

  #post-option-media-container ,
#event-option-media-container {
    border-top: dashed 1px $medBlue;
    background-color: rgba($medBlue, 0.15);
    transition: background-color 100ms;
    padding: 36px 12px 12px;

    #post-option-media-container-media-list ,
#event-option-media-container-media-list {
      display: flex;
    }

    .post-option-media-file-dragndrop {
      display:none;
    }

    .media-uploading,
    .media-upload-error,
    .media-upload-success {
      display: none;
    }

    #post-option-media-file ,
#event-option-media-file {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    #post-option-media-file-label ,
#event-option-media-file-label {
      cursor: pointer;
    }

    &.advanced-upload {
      min-height: 112px;
      .post-option-media-file-dragndrop {
        display:inline;
      }

      &.is-dragover {
        background-color: $baseWhite;
        .media-container-drop {
          display:block;
        }
      }
    }

    .media-container-drop {
      display:none;
      &::before {
        display: block;
        position: absolute;
        content: "\f067";
        font-family: "Font Awesome 5 Free";
        color: rgba($medBlue, 0.4);
        font-size: 36px;
        font-weight: 700;
        top: 16px;
        margin: auto;
        width: 100%;
        text-align: center;
        height: 32px;
        line-height: 32px;
      }
    }

    .media-container-drop,
    .media-container {
      position: relative;
      width: 64px;
      height: 64px;
      margin: 12px;
      border: dashed 3px $medBlue;
      border-radius: 4px;
      cursor: pointer;

      .media-container-preview {
        display: flex;
        background-size: contain;
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        z-index: 1;
        background-repeat: no-repeat;
        background-position: center center;

        video {
          max-width: 100%;
          max-height: 100%;
        }
      }

      &.populated {
        &:hover::after {
          display: block;
          position: absolute;
          font-family: "Font Awesome 5 Free";
          content: "\F2ED";
          color: $baseBlue;
          font-size: 16px;
          top: 4px;
          right: 4px;
          text-align: center;
          height: 16px;
          line-height: 16px;
          width: 16px;
          z-index: 2;
        }
      }
      &.uploading {
        .progress {
          display: block;
          content: ' ';
          background: #325c84;
          height: 8px;
          position: absolute;
          top: 42px;
          left: 4px;
          right: 60px;
          border-radius: 4px;
          z-index: 2;
        }
        .media-container-preview {
          opacity: 0.2;
        }
        &::before {
          display: block;
          content: 'uploading...';
          font-size: 10px;
          position: absolute;
          color: $baseBlue;
          top: 0;
          height: 64px;
          line-height: 64px;
          width: 100%;
          text-align: center;
          z-index: 2;
        }
      }
    }
  }

  .post-option {
    display: none;
    position: relative;

    .post-option-section-title {
      position: absolute;
      top: 8px;
      left: 8px;
      font-size: 24px;
      font-weight: bold;
      color: #95b5d4;
      z-index: 1;
    }
    .post-option-row{
      z-index: 2;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 6px;

      .post-option-label{
        padding-right: 12px;
        flex-basis: 96px;
        text-align: right;
      }

      .post-option-input{
        flex: 1;
      }
    }
  }

  .date-picker {
    z-index: 99;
    .DateInput_input {
      box-sizing: border-box;
    }
  }
}

#event-recurrence {
  .recurrence-type {
    display:none;
  }
  #recurrence-type-container {
    display: flex;
    background-color: $medBlue;

    .recurrence-type-option {
      background-color: $medBlue;
      flex-basis: 64px;
      flex-grow: 0;
      padding: 6px 12px;
      position: relative;
      color: $baseWhite;
      font-weight: bold;
      font-size: 14px;
      border-right: solid 1px white;
      cursor: pointer;
      text-align: center;
      transition: background-color 150ms, color 150ms;
      &.active,
      &:hover{
        background-color: $baseWhite;
        color: $baseBlue;
      }
    }
  }

  #recurrence-container {
    border: dashed 1px $medBlue;
    border-top: none;
    .recurrence-type {
      padding-top: 12px;
    }
  }
}

#event-rsvp {
  .rsvp-type {
    display:none;
  }
  #rsvp-type-container {
    display: flex;
    background-color: $medBlue;

    .rsvp-type-option {
      background-color: $medBlue;
      flex-basis: 64px;
      flex-grow: 0;
      padding: 6px 12px;
      position: relative;
      color: $baseWhite;
      font-weight: bold;
      font-size: 14px;
      border-right: solid 1px white;
      cursor: pointer;
      text-align: center;
      transition: background-color 150ms, color 150ms;
      &.active,
      &:hover{
        background-color: $baseWhite;
        color: $baseBlue;
      }
    }
  }

  #rsvp-container {
    border: dashed 1px $medBlue;
    border-top: none;
    .rsvp-type {
      padding-top: 12px;
    }
  }
}

.toggle-container {
  display: flex;

  .toggle-option {
    background-color: $medBlue;
    flex-basis: 88px;
    height: 24px;
    box-sizing: border-box;
    flex-grow: 0;
    padding: 6px 12px;
    position: relative;
    color: $baseWhite;
    font-weight: bold;
    font-size: 14px;
    border-right: solid 1px white;
    cursor: pointer;
    text-align: center;
    transition: background-color 150ms, color 150ms;

    &.active,
    &:hover{
      background-color: $baseWhite;
      color: $baseBlue;
    }
    input {
      display: none;
    }
    label {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      line-height: 26px;

    }
  }
}


.DateInput_fang {
  top: 30px !important;
}
.SingleDatePicker_picker {
  top: 40px !important;
}


.rsvp-form-row {
  .rsvp-form-column {

  }
}

.feedEnd {
  text-align: center;
  color: $baseBlue;
  font-weight: bold;
}


.post-list {
  margin-bottom: $spacing-large;

  .post-header {
    position: relative;
    font-weight: bold;
    cursor: pointer;

    &[data-sort-direction="desc"]::before,
    &[data-sort-direction="asc"]::before {
      display: block;
      position: absolute;
      font-family: "Font Awesome 5 Free";
      color: $baseBlue;
      font-size: 16px;
      top: -1px;
      right: 10px;
      text-align: center;
      height: 16px;
      line-height: 16px;
      width: 16px;
      z-index: 2;
    }
    &[data-sort-direction="desc"]::before {
      content: "\f0d7";
    }
    &[data-sort-direction="asc"]::before {
      content: "\F0D8";
    }
  }

  .post-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: solid 1px $medBlue;
    width: 100%;
    transition: background-color 100ms linear;

    &:hover {
      cursor: pointer;
      background-color: $lightBlue;
    }

    .post-author {
      flex-basis: 190px;
    }

    .post-subject {
      flex: 1;
    }

    .post-start-date {
      flex-basis: 175px;
    }

    .post-date {
      flex-basis: 175px;
    }

    .post-status {
      width: 90px;

    }

    &.status-pending {
      color: #90902a;
    }

    &.status-published {
      //color: #82c91e;
    }

    &.status-deleted {
      color: #e12914;
    }

    &.status-cancelled {
      color: #e12914;
    }

    &.status-revoked {
    }

    .post-menu {
      width: 32px;
    }
  }
}

.staff-post-list-header {
  @include flex-row(center, stretch);
  margin-bottom: 16px;

  .post-header-avatars {
    @include flex-row(center, center);
    padding-left: 16px;
    padding-right: 16px;
    //min-width: 102px;
    min-height: 64px;

    .avatar-link {
      .avatar:hover {
        box-shadow: 0 0 8px 3px rgba(102, 175, 233, 0.6);
      }
    }

    .avatar {
      $avatarSize: 54px;
      @include avatar($avatarSize);

      &.user-avatar {
        margin-left: -18px;
      }
    }
  }
  .post-organization,
  .post-author {
    color: $baseBlue;
    font-weight: 700;
  }
}

.rsvp-violation {
  background-color: #F8D7DA;
  color: #a10000;
}

.actn-post-unhide {
  cursor: pointer;
}

.actn-post-like-show .see-likes-avatars {
  display: grid;
}
.actn-post-like-show:hover .see-likes-avatars {
  display: none;
}
.see-likes-prompt {
  height: 1.6rem;
}
.actn-post-like-show .see-likes-prompt {
  display: none;
}
.actn-post-like-show:hover .see-likes-prompt {
  display: block;
}
.likes-avatar {
  max-width: 1.7rem;
  max-height: 1.7rem;
  border: solid 1px white;
  border-radius: 9999px;
  position: relative;
}
.likes-avatar:nth-child(1) {
  z-index: 10;
}
.likes-avatar:nth-child(2) {
  z-index: 11;
}
.likes-avatar:nth-child(3) {
  z-index: 12;
}
.likes-avatar:nth-child(4) {
  z-index: 13;
}
.likes-avatar:nth-child(5) {
  z-index: 14;
}

.post-comment-display {
  word-break: break-word;
}
