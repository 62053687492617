@font-face {
  font-family: 'clubster';
  src:  url('../fonts/clubster.eot?fuoga9');
  src:  url('../fonts/clubster.eot?fuoga9#iefix') format('embedded-opentype'),
  url('../fonts/clubster.ttf?fuoga9') format('truetype'),
  url('../fonts/clubster.woff?fuoga9') format('woff'),
  url('../fonts/clubster.svg?fuoga9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"], .icon-clubster {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'clubster' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clubster:before {
  content: "\e900";
}
