#wayfarer-container {
  background-color: rgba(37, 65, 91, 0.66);
  color: #ffffff;
  width: 916px;
  height: 365px;
  display: flex;
  position: relative;
  margin: 32px auto;
  border-radius: 6px;

  .call-out {
    padding:48px;
    h4 {
      color: #fff !important;
    }
    p {
      font-size: 75%;
    }
    .button {
      background-color: transparent;
      color: #cccccc;
      border: solid 1px #ddd;
      border-radius: 6px;
      width:128px;
      text-align: center;
      text-transform: uppercase;
      font-size: 0.8rem;
      padding:16px;
    }
  }

  .call-out {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    //margin-bottom: 16px;
    > div {
      flex: 1;
      &:last-of-type {
        flex: 0;
        align-self: flex-end;
        width: 100%;
      }
    }
    h1 {
      @include font-raleway();
      font-size: 24px;
      font-weight: 700;
    }
  }

  #registration-call-out {
    flex:1;
    .call-out {
      padding-right: 96px;
    }
  }
  #login-call-out {
    flex: 1;
    .call-out {
      padding-left: 96px;
    }
  }

  #wayfarer-action-slider {
    display: block;
    position: absolute;
    top:-16px;
    bottom: -16px;
    width: 505px;
    background-color: #ffffff;
    box-shadow: 0 2px 9px #222;
    color: #333;
    margin: auto;
    overflow: hidden;
    border-radius: 6px;
    border: 2px solid #ffffff;
    transition: border 150ms;

    #wayfarer-action-container {
      position: absolute;
      top: 32px;
      bottom: 32px;
      width: 916px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;
      //transition: left 150ms;

      .wayfarer-action {
        //position: absolute;
        //top: 0;
        //bottom: 0;
        flex: 0 0 438px;
        opacity: 1;
        transition: opacity 150ms;
      }

      #login-container {
        order: 2;
        .error.flash {
          background: darkred;
          padding: 12px;
          color: white;
          text-align: center;
          font-weight: bold;
          margin: -33px -37px 14px;
        }
      }

      #registration-container {
        //left: 0;
        order: 1;
        //background: cyan;
      }
    }

    &.login-active {
      &.authError {
        border: 2px solid darkred;
      }
      #login-container {
        display: flex;
        align-items: center;
        opacity: 1;
      }
      #registration-container {
        opacity: 0;
      }
    }

    &.registration-active {
      #login-container {
        opacity: 0;
      }
      #registration-container {
        opacity: 1;
      }
    }
  }

  .field-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .field-group-label {
      order: 1;
      flex: 1;
      text-align: right;
      margin-right: 16px;
    }
    .field-group-input {
      order: 2;
      flex: 4;
      input {
        box-sizing: border-box;
        width: 100%;
        padding:6px;
        &[type=checkbox] {
          width: auto;
        }
      }
      .password-toggle {
        float: right;
        margin-right: 0.75rem;
        margin-top: -1.75rem;;
        position: relative;
        z-index: 2;
      }
    }
  }
}

#login-form-container {
  flex: 1;
}

#registration-form-terms {
  font-size: 11.5px;
  word-spacing: 1px;
}

#mobile-wayfarer {
  h2 {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 8px;
    color: white;

  }

  input {
    margin-bottom: $spacing-small;
    &[type=password],
    &[type=email],
    &[type=text] {
      width: 100%;
      box-sizing: border-box;
    }
  }
  #registration-form-terms {
    margin-bottom: $spacing-small;
  }

  .password-toggle {
    float: right;
    margin-right: 0.75rem;
    margin-top: -1.5rem;;
    position: relative;
    z-index: 2;
  }

}
