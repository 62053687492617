.rsvp-loggedin-user {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d4eaff+0,ffffff+100&1+0,0+100 */
  background: -moz-linear-gradient(-45deg, rgba(212,234,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, rgba(212,234,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, rgba(212,234,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4eaff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.rsvp-list {
  .rsvp {
    margin-bottom: $spacing-small;
  }

  .rsvp-row-header,
  .rsvp-row {
    @include flex-row();
    padding: $spacing-small;

    .rsvp-creator{
      @include column;
      max-width: 225px;
    }
    .rsvp-attendees{
      @include column;
      max-width: 100px;
    }
    .rsvp-phone{
      @include column;
      max-width: 150px;
    }
    .rsvp-notes{
      @include column;
    }
    .rsvp-created-datetime{
      @include column;
      max-width: 100px;
    }
    .rsvp-actions{
      @include column;
      text-align: right;
    }
  }
  .rsvp-row-header {
    .rsvp-creator,
    .rsvp-attendees,
    .rsvp-phone,
    .rsvp-notes,
    .rsvp-created-datetime{
      font-weight: bold;
    }
  }

  .rsvp-details {
    display: none;
    padding: $spacing-small;

    .rsvp-attendee-list {
      .rsvp-attendee-header,
      .rsvp-attendee {
        @include flex-row();

        .rsvp-attendee-name {
          @include column;
          max-width: 225px;
        }

        .rsvp-attendee-email {
          @include column;
          max-width: 225px;
        }

        .rsvp-attendee-age {
          @include column;
          max-width: 100px;
        }

        .rsvp-attendee-notes {
          @include column;
        }
      }

      .rsvp-attendee-header {
        .rsvp-attendee-name,
        .rsvp-attendee-email,
        .rsvp-attendee-age,
        .rsvp-attendee-notes {
          font-weight: bold;
        }
      }
    }
  }
}


