.club-link-category {
  &.category-drag {
    opacity: 0.5;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  &.category-drag-mirror {
    max-width: 60%;
    .club-link-menu,
    .club-link-list {
      display:none;
    }
  }

  .club-link-category-handle {
    cursor: grabbing;
  }

  .club-link-menu {
    width: 32px;
    padding-left: 6px;
    order: 4;
  }

  .club-link-list {
    min-height: 12px;
  }
  .club-link-line {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-bottom: $spacing-small;
    /* border: dashed 1px $medBlue; */
    margin-bottom: 3px;

    .club-link-handle {
      cursor: grabbing;
      color: #95b5d4;
      width: 16px;
      order:1;
    }
    .club-link-name {
      flex-basis: 450px;
      white-space: nowrap;
      text-overflow: ellipsis;
      /* max-width: 250px; */
      /* width: 250px; */
      overflow: hidden;
      order: 2;
    }
    .club-link-url {
      white-space: nowrap;
      text-overflow: ellipsis;
      order: 3;
      color: $medBlue;
      background: white;
      padding-left: 6px;
      padding-right: 6px;
      flex: 1;

      overflow: hidden;
      max-width:540px;
      border: solid 1px $medBlue;
    }
    .club-link-menu {
      width: 32px;
      padding-left: 6px;
      order: 4;
    }
    &.club-link-drag-over {
      background-color: $lightBlue;
      border: dashed 1px $baseBlue;
    }
  }

  .club-link-drag-mirror {
    max-width: 60%;
    .club-link-url,
    .club-link-menu {
      display: none;
    }
  }
}

.club-link-is-dragging {
  .club-link-category {
    .section-title {
      color: #74b274 !important;
    }
    border: dashed 1px #74b274 !important;
    background-color: #e3f9e2 !important;
  }
}
