.product-table {
  display: flex;
  flex-direction: row;

  .product-column {
    background-color: #ffffff;
    border-radius: 3px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 16px;
    flex-grow: 1;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    .product {
      .product-header {
        background: #325c84;
        border-color: #ffffff;
        border-style: solid;
        border-width: 2px;
        border-radius: 3px;
        padding: 32px;
        color: #ffffff;

        .product-title {
          margin-top: -12px;
          margin-bottom: 12px;
        }

        .product-price {
          margin: 16px;
          font-size: 42px;
          font-weight: bold;
          text-align: center;
          position: relative;
          .product-price-symbol {
            font-size: 24px;
            position: absolute;
            top: 0px;
            left: -12px;
          }
          .product-price-period {
            font-size: 18px;
            text-transform: lowercase;
            position: absolute;
            bottom: 0px;
            right: -38px;
          }
        }

        .product-select-button {
          label {
            span {
              display: block;
              border: solid 1px #ffffff;
              border-radius: 3px;
              padding: 8px;
              text-align: center;
              cursor: pointer;
              &:hover {
                font-weight: bold;
              }
            }

            .product-selected-btn-label {
              display:none;
            }
            input[type="radio"] {
              display: none;
            }
            input[type="radio"]:checked ~ .product-select-btn-label {
              display:none !important;
            }
            input[type="radio"]:checked ~ .product-selected-btn-label {
              display: block !important;
              background: #95b5d4;
            }
          }
        }
      }

      .product-details {
        padding: 12px 24px 24px;

        .product-detail {
          padding: 8px;
          border-bottom: solid 1px #dbdbdb;
          color: #747171;
        }
      }
    }
  }
}
