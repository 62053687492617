.media-container {
  display: flex;
  &.horizontal {
    flex-direction: column;
  }
  &.vertical {
    flex-direction: row;
  }
  height: 480px;
  .media-container-col {
    flex:1;
    display: flex;

    &.horizontal {
      flex-direction: row;
      &:nth-of-type(2) {
        max-height: 34%;
      }
    }

    &.vertical {
      flex-direction: column;
      &:nth-of-type(2) {
        max-width: 34%;
      }
    }


  }
  .media-item {
    flex: 1;
    background-size: cover;
    margin: 3px;
    background-color: rgba(255,255,255, 0.0) !important;
    background-blend-mode: color;
    background-position: center center;

    transition: background-color 125ms linear;
    cursor: pointer;

    &.media-item-video {
      position: relative;

      &:after {
        position: absolute;
        content: "\f144";
        //font-family: FontAwesome;
        font-family: 'Font Awesome 5 Free';
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        font-size: 128px;
        color: #ffffff;
        text-shadow: 0 0 10px black;
      }
    }

    &.media-item-youtubevideo {
      position: relative;

      &:after {
        position: absolute;
        content: "\f167";
        //font-family: FontAwesome;
        font-family: 'Font Awesome 5 Brands';
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        font-size: 128px;
        color: #ffffff;
        text-shadow: 0 0 10px black;
      }
    }

    &:hover {
      background-color: rgba(255,255,255, 0.3) !important;
    }

    &.additional-media-items {
      background-color: rgba(255,255,255, 0.4) !important;
      background-image: url("../../images/mute_geo_bg.jpg");
      background-blend-mode: color;
      color: #fff;
      font-weight: bold;
      font-size: 26px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      transition: background-color 125ms linear,
                  color 125ms linear,
                  font-size 258ms cubic-bezier(0.680, -0.550, 0.265, 1.550);

      &:hover {
        background-color: rgba(255,255,255, 0.85) !important;
        color: $baseBlue;
        font-size: 32px;
      }
    }
  }
}

.media-viewer {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  z-index: 99;

  .media-viewer-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($baseBlack, 0.65);
    z-index: 99999;
  }
  .media-viewer-content {
    position: relative;
    background: #ffffff;
    box-shadow: 0 1px 4px rgba($baseBlack,.24);
    border-radius: 3px;
    padding: 16px;
    margin-bottom: 32px;
    max-height: 75%;
    max-width: 75%;
    z-index: 999991;

    .media-viewer-slider {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .media-viewer-item-container {
        flex: 1;
      }
    }
  }

  .media-viewer-prev,
  .media-viewer-next {
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 128px;
    width: 45px;
    font-size: 96px;
    line-height: 115px;
    text-shadow: 1px 1px 2px black;
    transition: font-size 258ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
    cursor: pointer;
    &:hover {
      font-size: 128px;
    }
  }
  .media-viewer-prev {
    left: -64px;
    &:before {
      position:absolute;
      content: "\f104";
      font-family: 'Font Awesome 5 Free';
      //font-family: FontAwesome;
      left:0;
      right:0;
      top:0;
      text-align: center;
    }
  }
  .media-viewer-next {
    right: -64px;
    &:before {
      content: "\f105";
      //font-family: FontAwesome;
      font-family: 'Font Awesome 5 Free';
      left:0;
      right:0;
      top:0;
      text-align: center;
    }}
  .media-viewer-info {
    position: absolute;
    bottom: -24px;
    color: #fff;
    left: 16px;
  }
  .media-viewer-close {
    position: absolute;
    top: -28px;
    right: -28px;
    width: 24px;
    height: 24px;
    font-size: 32px;
    color: #fff;
    cursor: pointer;
    &:before {
      position:absolute;
      content: "\f00d";
      //font-family: FontAwesome;
      font-family: 'Font Awesome 5 Free';
      left:0px;
      top:0;
    }}
}

.media-viewer-scroll-disabled {
  overflow: hidden;
}
