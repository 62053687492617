.feed-link {
      color: $baseBlack;
      text-decoration: none;
}

.feed {
  padding: 6px;
  background: #ffffff;
  transition: background 250ms;
  border-radius: 4px;
  color: $baseBlack;
  text-decoration: none;
  align-items: center !important;

  .club-avatar {
    display: inline-block;
    //background: url(../../images/logo/logo.svg) no-repeat;
    border-radius: 200px;
    margin: 4px;
    marrgin-left: 0px;
    height: 32px;
    width: 32px;
    flex-basis: 32px;
    overflow: hidden;
  }
  .feed-avatar {
    display: inline-block;
    //background: url(../../images/logo/logo.svg) no-repeat;
    border-radius: 200px;
    margin: 4px;
    marrgin-left: 0px;
    height: 32px;
    width: 32px;
    flex-basis: 32px;
    overflow: hidden;
  }

  >.avatar:first-of-type {
    margin-left: 0px;
  }

  >.avatar:nth-of-type(2) {
    margin-left: -16px;
  }

  .feed-title {
    max-width: 208px;
    align-self: center;
    margin-left: auto;

    &.twoLines {
    }
  }

  .feed-title-left {
    align-self: center;
    margin-left: 12px;

    &.twoLines {
    }
  }

  .subtext{
    font-size: 12px;
  }

  &:hover {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d4eaff+0,ffffff+100&1+0,0+100 */
    background: -moz-linear-gradient(-45deg, rgba(212,234,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(212,234,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(212,234,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4eaff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }
}
