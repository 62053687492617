.menu-row {
  padding: 6px;
  background: #ffffff;
  transition: background 250ms;
  border-radius: 4px;
  color: $baseBlack;
  text-decoration: none;
  @include flex-row(center, stretch);

  .menu-avatar {
    display: inline-block;
    //background: url(../../../images/logo/logo.svg) no-repeat;
    border-radius: 200px;
    margin: 4px;
    height: 32px;
    width: 32px;
    flex-basis: 32px;
    overflow: hidden;
  }

  .menu-title {
    margin-left: 12px;
  }

  &:hover {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d4eaff+0,ffffff+100&1+0,0+100 */
    background: -moz-linear-gradient(-45deg, rgba(212,234,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(212,234,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(212,234,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4eaff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }
}
