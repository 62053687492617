#floating-nav-container {
  justify-self: center;
  flex: 1 0 auto;
  max-width: $baseMaxWidth;
  width: $baseMaxWidth;
  @include flex-row(flex-start, stretch);
  margin:auto;
  height: 32px;
  max-height: 32px;
  overflow: visible;
}

.floating-nav {
  background: #ffffff;
  box-shadow: 0 1px 4px rgba($baseBlack,.24);
  border-radius: 6px;
  margin-bottom: 32px;
  margin-top: -50px;
  display: flex;
  flex-direction: row;
  height: 80px;
  box-sizing: border-box;

  .floating-nav-item {
    flex: 1;
    text-align: center;
    color: rgba($baseBlack, 0.4);
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-bottom-color: #FFFFFF;
    position: relative;
    transition: border-bottom-color 150ms;

    a{
      color: rgba($baseBlack, 0.4);
      text-decoration: none;
      display: block;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      padding: 8px;
      transition: color 150ms;

      i {
        font-size: 36px;
        margin-bottom: 8px;
      }

      .badge {
        transform: scale(1.2);
      }
    }

    &.active {
      border-bottom-color: $medBlue;
      a {
        color: $medBlue;
      }
    }

    &:hover {
      border-bottom-color: $baseBlue;
      a {
        color: $baseBlue;
      }
    }
  }
}


.left-col-menu {
  a {
    text-decoration: none;
  }
  li {
    padding: 8px;
    text-decoration: none;
    color: rgba($baseBlack, 0.7);
    border-left-color: #ffffff;
    border-left-style: solid;
    border-left-width: 3px;
    transition: border-bottom-color 150ms, color 150ms;

    &:hover {
      border-left-color:$baseBlue;
      color: $baseBlue;
    }
  }
}

.left-col-menu-active-item {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d4eaff+0,ffffff+100&1+0,0+100 */
  background: -moz-linear-gradient(-45deg, rgba(212,234,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, rgba(212,234,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, rgba(212,234,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4eaff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
