.table {
  display: table;
  margin-bottom: $spacing-large;

  .table-header {
    display: table-header-group;
    position: relative;
    font-weight: bold;
    cursor: pointer;

    .cell {
      display: table-cell;
      padding-bottom: 6px;
      padding-top: 6px;
      vertical-align: middle;
    }

    &[data-sort-direction="desc"]::before,
    &[data-sort-direction="asc"]::before {
      display: block;
      position: absolute;
      font-family: "Font Awesome 5 Free";
      color: $baseBlue;
      font-size: 16px;
      top: -1px;
      right: 10px;
      text-align: center;
      height: 16px;
      line-height: 16px;
      width: 16px;
      z-index: 2;
    }
    &[data-sort-direction="desc"]::before {
      content: "\f0d7";
    }
    &[data-sort-direction="asc"]::before {
      content: "\F0D8";
    }
  }

  .table-body {
    display: table-row-group;
  }

  .table-row {
    display: table-row;
    //border-bottom: solid 1px $medBlue;
    width: 100%;

    //&:nth-of-type(odd) {
    //  background-color: #e4ebf2bf;
    //}

    .cell {
      display: table-cell;
      padding: $spacing-small;
      vertical-align: middle;
    }
  }
}
