.club-stat,
.totals-stat {
  @apply bg-white;
  @apply shadow-clubster;
  @apply rounded-md;
  @apply overflow-hidden;
  @apply flex;
  @apply flex-col;

  .club-stat-value,
  .totals-stat-value {
    @apply flex-grow;
    @apply text-7xl;
    @apply text-center;
    @apply text-baseBlue;
    @apply font-normal;
    @apply p-4;
    @apply lining-nums;
    @apply tabular-nums;
    @apply slashed-zero;
    @apply order-first;
    content: '0';
  }

  .club-stat-label,
  .totals-stat-label {
    @apply flex-none;
    @apply bg-baseBlue;
    @apply text-white;
    @apply capitalize;
    @apply font-bold;
    @apply p-4;
    @apply text-center;
    @apply tracking-wide;
    @apply order-last;
  }

  .club-stat-top-label,
  .totals-stat-top-label {
    @apply flex-none;
    @apply bg-baseBlue;
    @apply text-white;
    @apply capitalize;
    @apply font-bold;
    @apply pt-2;
    @apply px-4;
    @apply text-center;
    @apply tracking-wide;
    @apply order-last;
  }

  .club-stat-sub-label,
  .totals-stat-sub-label {
    @apply flex-none;
    @apply bg-baseBlue;
    @apply text-white;
    @apply capitalize;
    @apply px-4;
    @apply pb-2;
    @apply text-center;
    @apply tracking-wide;
    @apply order-last;
  }


  .club-stat-breakdown,
  .totals-stat-breakdown {
    @apply flex-grow;
    @apply text-sm;
    @apply slashed-zero;
    @apply pb-2;
    @apply text-center;
    @apply flex;
    @apply flex-row;
    @apply justify-evenly;
    @apply items-center;
    //@apply px-3;

    .club-stat,
    .totals-stat {
      @apply shadow-none;
      @apply rounded-none;
      @apply overflow-hidden;
      @apply flex;
      @apply flex-row;
      @apply border-none;
      @apply items-center;
      @apply justify-center;

      /* @apply clb-label; */
      @apply px-2;
      @apply inline-flex;
      @apply text-xs;
      @apply leading-6;
      @apply font-semibold;
      @apply rounded-full;

      @apply bg-blue-100;
      @apply text-blue-800;

      .club-stat-value,
      .totals-stat-value {
        @apply flex-grow;
        @apply order-last;

        @apply text-xs;
        @apply text-right;
        @apply text-blue-800;
        @apply font-normal;
        @apply p-0;
        @apply lining-nums;
        @apply tabular-nums;
        @apply slashed-zero;
      }

      .club-stat-label,
      .totals-stat-label {
        @apply flex-grow;
        @apply order-first;

        @apply bg-transparent;
        @apply text-blue-800;
        @apply capitalize;
        @apply font-normal;
        @apply p-0;
        @apply pr-0.5;
        @apply text-left;
        @apply tracking-normal;

        &:after {
          content: ':';
        }
      }
    }
  }
}