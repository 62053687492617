.invitation-list {
  display: table;
  margin-bottom: $spacing-large;

  .invitation-header {
    display: table-header-group;
    position: relative;
    font-weight: bold;
    cursor: pointer;

    .cell {
      display: table-cell;
      padding-bottom: 6px;
      padding-top: 6px;
    }

    &[data-sort-direction="desc"]::before,
    &[data-sort-direction="asc"]::before {
      display: block;
      position: absolute;
      font-family: "Font Awesome 5 Free";
      color: $baseBlue;
      font-size: 16px;
      top: -1px;
      right: 10px;
      text-align: center;
      height: 16px;
      line-height: 16px;
      width: 16px;
      z-index: 2;
    }
    &[data-sort-direction="desc"]::before {
      content: "\f0d7";
    }
    &[data-sort-direction="asc"]::before {
      content: "\F0D8";
    }
  }

  .invitation-list-items {
    display: table-row-group;
  }

  .invitation-item {
    display: table-row;
    border-bottom: solid 1px $medBlue;
    width: 100%;

    &:nth-of-type(odd) {
      background-color: #e4ebf2bf;
    }

    .cell {
      display: table-cell;
      padding-bottom: 6px;
      padding-top: 6px;
    }

    //.invitation-name {
    //  flex-basis: 250px;
    //}
    //
    //.invitation-email {
    //  flex: 1;
    //}
    //
    //.invitation-date {
    //  flex-basis: 125px;
    //}
    //
    //.invitation-status {
    //  width: 32px;
    //
    //  &.status-pending {
    //    color: #90902a;
    //  }
    //
    //  &.status-accepted {
    //    color: #82c91e;
    //  }
    //
    //  &.status-declined {
    //    color: #e12914;
    //  }
    //
    //  &.status-revoked {
    //  }
    //}
    //
    //.invitation-menu {
    //  width: 32px;
    //}
  }
}


.member-status-invited,
.member-status-pending {
  background: #DFE1E6 !important;
  border: 1px solid #DFE1E6 !important;
  color: #253858 !important;
}

.member-status-accepted {
  background-color: #00875A !important;
  border-color: #00875A !important;
  color: #FFFFFF !important;
}

.member-status-declined {
  background-color: #DE350B !important;
  border-color: #DE350B !important;
  color: #FFFFFF !important;
}

.member -status-revoked {
  background-color: #FFAB00 !important;
  border-color: #FFAB00 !important;
  color: #253858 !important;
}

