.cover-container-mobile {
    /* flex-grow: 1; */
    /* width: 100%; */

  #cover-mobile {
/*
    max-height: 320px;
    background-color: $medBlue;
    background-size: cover;
    background-position-y: center;
    background-repeat: no-repeat;
*/
    #cover-content-mobile {
      /* @include flex-row(flex-end); */
      /* /* max-width: $baseMaxWidth; */
/*
      justify-content: center;
      width: 80%;
      margin:auto;
      height: 100%;
      padding: 0.25rem;
      box-sizing: border-box;
*/
     #cover-details-mobile {
        /* flex-grow: 1; */
        /* margin-left: 0.5rem; */
        /* margin-right: 0.5rem; */
        /* text-shadow: 0 0 18px rgba($baseBlack,.4); */
        #cover-name-mobile {
          font-size: 1.5rem;
          color: $baseWhite;
          font-weight: bold;
          margin-bottom: 16px;
        }
        #cover-sub-name-mobile {
          font-size: 1.25rem;
          margin-left: 18px;
        }
      }
    }
  }
}

#cover-container {

  #cover {
    max-height: 320px;
    background-color: $medBlue;
    background-size: cover;
    background-position-y: center;
    background-repeat: no-repeat;
    //background: url(../../images/mute_geo_cover.jpg);
    &[style^='background-image'] {
      height: 320px;
      #cover-content{
        #cover-details{
          text-shadow: 2px 3px 6px rgb(26, 26, 26) !important;
          #cover-controls {
            height:28px;
            a:not(.clear-style) {
              cursor: pointer;
              background: rgba($baseBlue, 0.40);
              text-shadow: 2px 3px 6px rgb(26, 26, 26) !important;
              box-shadow: 2px 3px 6px rgb(26, 26, 26) !important;
              transition: background 175ms linear, box-shadow 175ms linear;

              &:hover {
                background: rgba($baseBlue, 0.75);
                box-shadow: 1px 2px 4px rgb(26, 26, 26) !important;
              }
            }
          }
        }
      }
    }

    #cover-content {
      @include flex-row(flex-end);
      max-width: $baseMaxWidth;
      margin:auto;
      height: 100%;
      padding: 24px;
      box-sizing: border-box;

      .cover-avatar {
        @include avatar(220px);
        border: solid 3px white;
        box-shadow: 0 0 18px rgba($baseBlack,.4);
      }

      #cover-details {
        flex-grow: 1;
        margin-left: 20px;
        margin-bottom: 72px;
        text-shadow: 0 0 18px rgba($baseBlack,.4);
        #cover-name {
          font-size: 48px;
          color: $baseWhite;
          font-weight: bold;
          margin-bottom: 16px;
        }
        #cover-sub-name {
          font-size: 18px;
          margin-left: 18px;
        }
        #cover-controls {
          a:not(.clear-style) {
            cursor: pointer;
            display: inline-block;
            padding: 6px 16px;
            background: rgba($baseBlue, 0.25);
            color: $baseWhite;
            border: solid 1px $baseWhite;
            border-radius: 3px;
            box-shadow: 0 0 18px rgba($baseBlack,.4);
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
            text-decoration: none;
            transition: background 75ms linear, box-shadow 75ms linear;

            &:hover {
              background: rgba($baseBlue, 0.75);
              box-shadow: 1px 2px 4px rgb(26, 26, 26) !important;
            }
          }
        }
      }
    }
  }
}

#nonauth-cover {
  #cover {
    height: 280px !important;

    #cover-content {
      justify-content: center !important;

      #cover-details {
        flex-grow: unset !important;

        #cover-callout {
          .content-box {
            background: #ffffff;
            box-shadow: 0 1px 4px rgba($baseBlack, .24);
            border-radius: 3px;
            padding: 16px;
            text-shadow: none !important;
          }
        }
      }
    }
  }
}
