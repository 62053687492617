@import "../../css/includes/global_settings";

$topbarBaseHeight: 24px;
$topbarWidth: 100%;
$topbarBaseColor: $baseBlue;


.topbar {
  padding: 16px;
  background-color: $topbarBaseColor;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
  color: $baseWhite;
  height: $topbarBaseHeight;
  box-sizing: content-box !important;
  @include flex-row();

  #topbar-inner {
    box-sizing: content-box !important;
    flex: 1;
    @include flex-row();
    #topbar-left {
      order: 0;
      @include flex-row(center, flex-start);
    }
    #topbar-center {
      order: 1;
      @include flex-row(center, center);
    }
    #topbar-right {
      order: 2;
      @include flex-row(center, flex-end);
    }

    .topbar-item {
      cursor: pointer;
      margin-left: 6px;
      position: relative;

      a {
        padding: 16px;
        background-color: rgba($topbarBaseColor, 1);
        color: rgba($baseWhite, 0.5);
        text-decoration: none;
        transition: color 250ms, background-color 250ms;
        border-radius: 4px;

        .avatar {
          display: inline-block;
          //background: url(../../images/logo/logo.svg) no-repeat;
          border-radius: 200px;
          margin: 4px;
          margin-left: 0px;
          height: 32px;
          width: 32px;
          flex-basis: 32px;
          overflow: hidden;
        }
      }

      .topbar-item-drawer {
        position: absolute;
        top: 44px;
        width: 230px;
        right: 0;
        background-color: $topbarBaseColor;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        box-shadow: 0 1px 4px rgba(26,26,26,0.24);
        overflow: hidden;
        max-height: 0;
        transition: max-height 150ms;
        //padding-left: 16px;
        //padding-right: 16px;

        .topbar-item {
          margin: 8px;
          a,div{
            display: block;
          }
          &:first-of-type {
            margin-top: 16px;
          }

          &:last-of-type {
            margin-bottom: 16px;
          }

        }
      }

      &:hover {
        a {
          color: rgba($baseWhite, 1);
          background-color: rgba($baseWhite, 0.1);
        }
        .topbar-item-drawer {
          max-height: 1000px;

          .topbar-item {
            a, div {
              padding: 16px;
              background-color: rgba($topbarBaseColor, 1);
              color: rgba($baseWhite, 0.5);
              transition: color 250ms, background-color 250ms;
              border-radius: 4px;
            }

            &:hover {
              a, div {
                color: rgba($baseWhite, 1);
                background-color: rgba($baseWhite, 0.1) !important;
              }
            }
          }
        }
      }
    }
  }

  //#topbar-search-container {
  //  min-width: 100px;
  //  padding-top: 6px;
  //}

  &.mobile-topbar {
    #topbar-inner {
      #topbar-right {

      }
    }
  }
}

#title-link {
  color: $baseWhite;
  text-decoration: none;
}
#title-container {
  padding-left: 58px;
  position: relative;
  z-index: 999999;
  #title-logo {
    background: url(../../images/logo/logo.svg) no-repeat;
    width: 54px;
    height: 54px;
    display: block;
    position: absolute;
    left: -5px;
    top: 3px;
    z-index: 4;
  }
  #title-text {
    @include font-opensans();
    font-size: 32px;
    color: $baseWhite;
    text-decoration: none;
  }
  #title-underline {
    background: #fff;
    height: 1px;
    position: absolute;
    bottom: 0px;
    left: -17px;
    right: 210px;
    z-index: 2;
    transition: right 250ms;
  }

  &:hover {
    #title-underline {
      right: 0px;
    }
  }
}

#search-container {
  order: 999;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  color: $baseWhite;
  #search-tip {
    //display: none;
    color: rgba($baseWhite, 0.5);
    margin-right: 8px;
    opacity: 100;
    max-width: 0px;
    transition: opacity 250ms, max-width 250ms;
    cursor: pointer;
    overflow: hidden;
  }

  #search-results-container {
    position: absolute;
    right: 0;
    top: 54px;
    background-color: #325c84;
    width: 460px;
    max-width: 460px;
    max-height: 0px;
    border-bottom-left-radius: 4px;
    box-shadow: 0 1px 4px rgba($baseBlack,.24);
    overflow: hidden;
    transition: max-height 150ms, max-width 150ms, border-bottom-left-radius 75ms;
    #search-results {
      padding: 16px;
    }

    #search-no-results {
      text-align: center;
    }

    .search-result-spinner {
      margin: 0px auto;
      height: 40px;
      text-align: center;
      font-size: 10px;
      opacity: 1;
      transition: opacity 100ms;

      > div {
        background-color: #fff;
        height: 100%;
        width: 8px;
        display: inline-block;
        margin-left: 1px;
        margin-right: 1px;

        -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
        animation: sk-stretchdelay 1.2s infinite ease-in-out;
      }

      .rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
      }

      .rect3 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
      }

      .rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
      }

      .rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
      }

      @-webkit-keyframes sk-stretchdelay {
        0% {
          -webkit-transform: scaleY(0.4);
          opacity: 0;
        }
        40%, 100% {
          -webkit-transform: scaleY(0.4);
          opacity: 0.7;
        }
        20% {
          -webkit-transform: scaleY(1.0);
          opacity: 1;
        }
      }

      @keyframes sk-stretchdelay {
        0% {
          transform: scaleY(0.4);
          -webkit-transform: scaleY(0.4);
          opacity: 0;
        }
        40%, 100% {
          transform: scaleY(0.4);
          -webkit-transform: scaleY(0.4);
          opacity: 0.7;
        }
        20% {
          transform: scaleY(1.0);
          -webkit-transform: scaleY(1.0);
          opacity: 1;
        }
      }
    }
  }

  &.has-input {
    #search-results-container {
      max-height: 600px;
      overflow: auto;
    }
  }

  &.has-results {
    #search-results-container {
      border-bottom-left-radius: 36px;
      #search-results {
        padding: 0px;
      }
      .search-result-spinner {
        opacity: 0;
        display:none;
      }
    }
  }

  &.collapsed {
    #search-tip {
      max-width: 150px;
      opacity: 100;
    }
    &:hover {
    }
  }

  #search-icon {
    @include fa-icon;
    @extend .fas;

    order: 0;
    cursor: pointer;

    &:before {
      content: fa-content($fa-var-search)
    }
  }

  #search-form-container {
    order: 1;
    flex-grow: 1;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    overflow: hidden;
    max-width: 400px;

    transition: max-width 250ms ease-in;

    #search-input-container {
      order: 0;
      flex-grow: 1;
      padding-left: 8px;
      padding-right: 8px;

      input {
        width: 320px;
        background: $baseBlue;
        color: $baseWhite;
        @include font-opensans;
        border: none;
        border-bottom: solid 1px $baseWhite;

        &::placeholder { /* Chrome/Opera/Safari */
          color: rgba($baseWhite, 0.5);
        }
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: rgba($baseWhite, 0.5);
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: rgba($baseWhite, 0.5);
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: rgba($baseWhite, 0.5);
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: rgba($baseWhite, 0.5);
        }

        &:focus {
          outline: none;
        }
      }
    }

    #search-close-button-container {
      @include fa-icon;
      @extend .fas;

      order: 1;
      cursor: pointer;

      &:before {
        content: fa-content($fa-var-times)
      }
    }
  }

  &.collapsed {
    #search-results-container {
      max-width: 0 !important;
      overflow: hidden !important;
    }

    #search-form-container {
      max-width: 0px;
    }
  }

}

.result-link {
  background-color: #fff;
  display: block;
  margin: 16px;
  border-radius: 27px;
  box-shadow: 0 2px 9px #222;
  text-decoration: none;

  .search-result {
    align-items: center;

    .search-result-avatar {
      $avatarSize: 48px;
      @include avatar($avatarSize);
    }
    .search-result-title {
      color: #325c84;
      text-decoration: none;
      font-size: 16px;
      font-weight: 700;
      margin-left: 16px;
    }

    &.search-result-type-generic {
      .search-result-avatar {
        $avatarSize: 48px;
        @include avatar($avatarSize);
        color: $baseBlue;
        background: url(../../images/compass-regular.svg) no-repeat;
      }
    }
  }
}
