$labelWidth: 96px;
$labelWidthWide: 156px;
$labelWidthUltraWide: 256px;

input[type=time],
input[type=date],
input::-webkit-calendar-picker-indicator {
  cursor: pointer!important;
}

.form {
  .form-row {
    .form-row-label {
      margin-top: 8px;
    }
    .form-row-input {
      border-radius: 2px;
      border: 1px solid #dbdbdb;
      margin: 0;
      margin-bottom: 8px;
      padding: 0;
      background: #fff;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 100%;

      input {
        font-weight: 200;
        color: #484848;
        background-color: #fff;
        width: 100%;
        border: 0;
        border-radius: 0;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: .2px;
        padding: 7px 7px 5px;
        box-sizing: border-box;
      }
    }
  }
}

.form-error {
  .form-section-label {
    color: $darkRed !important;
    font-weight: bold;
  }
  textarea,
  select,
  input[type=email],
  input[type=password],
  input[type=text] {
    color: $darkRed !important;
    border-color: $darkRed !important;
    background-color: #ffecec;
  }
  .form-section-error {
    color: $darkRed !important;
    font-weight: bold;
  }
}

[data-wide] {
  .form-section-label {
    flex-basis: $labelWidthWide !important;
  }
}
[data-ultrawide] {
  .form-section-label {
    flex-basis: $labelWidthUltraWide !important;
  }
}

.form-section-row {
  &.hidden {
    display: none !important;
  }
  .form-section {
    margin-bottom: $spacing-large;
    flex: auto;
  }
}

.form-section {
  position: relative;
  &.hidden {
    display: none;
  }
  .form-section-container {
    @extend .section-container;
    //position: relative;
    border: none;
    border-radius: 0 !important;
    margin-bottom: 0 !important;
    &:not(:first-of-type) {
      border-top: dashed 1px $medBlue !important;
    }
    //background-color: $lightBlue;
    //transition: background-color 100ms;
    //padding: 12px 12px;
  }

  .form-section-title {
    @extend .section-title;
    ////position: absolute;
    //top: 8px;
    //left: 8px;
    //font-size: 24px;
    //font-weight: bold;
    //color: #95b5d4;
    //z-index: 1;
  }
  .form-group-inputs {
    display: flex;
    flex-direction: row;
  }
  .form-section-row{
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 6px;

    .form-section-label{
      padding-right: 12px;
      flex-basis: $labelWidth;
      text-align: right;
      margin-top: 8px;
      font-weight: 600;
      color: $baseBlue;
      align-self: baseline;

      label.required {
        position: relative;
        &::before {
          display: block;
          width: 14px;
          height: 14px;
          position: absolute;
          content: '*';
          right: -9px;
          top: 0px;
          color: $darkRed;
        }
      }

      &.wide {
        flex-basis: $labelWidthWide !important;
      }

      &.ultrawide {
        flex-basis: $labelWidthUltraWide !important;
      }
    }

    .form-section-value{
      flex: 1;
      margin-top: 8px;
    }
    .form-section-input{
      flex: 1;

      textarea,
      select,
      input[type=email],
      input[type=password],
      input[type=number],
      input[type=date],
      input[type=time],
      input[type=text] {
        box-sizing: border-box;
        border: 1px solid #dbdbdb;
        padding: 6px;
        font-size: 16px;
        width: 100%;
      }
    }

    .form-section-select-group {
      display: flex;

      select {
        flex: 1;
      }
    }
  }
}

select {
  padding: 6px 35px 6px 6px;
  font-size: 16px;
  border: 1px solid #dbdbdb;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../../images/chevron-down-solid.svg) no-repeat #fff;
  background-size: 16px;
  background-position: calc(100% - 6px) center;
  cursor: pointer;
  margin-right: 16px;
  &::-ms-expand {
    display: none; /* remove default arrow on ie10 and ie11 */
  }

  &[disabled] {
    background-color: #e3e3e3;
  }
}


@mixin baseButton {
  background: $baseBlue;
  color: $baseWhite;
  border: solid 1px $baseBlue;
  border-radius: 3px;
  padding: 12px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  transition: background-color 150ms;
  cursor: pointer;
  text-decoration: none;
  display: block;
  text-align: center;
  &:hover{
    background-color: $medBlue;
  }
}

//button,
//input[type=submit],
//input[type=button],
//input[type=reset],
//.button,
//.clubster-button {
//  white-space: nowrap;
//  background: $baseBlue;
//  color: $baseWhite;
//  border: solid 1px $baseBlue;
//  border-radius: 3px;
//  padding: 12px;
//  box-sizing: border-box;
//  font-size: 16px;
//  line-height: 16px;
//  font-weight: 600;
//  cursor: pointer;
//  text-decoration: none;
//  display: block;
//  text-align: center;
//  transition: background-color 100ms linear, color 100ms linear;
//  &.hollow {
//    border: solid 1px $baseBlue;
//    color: $baseBlue;
//    background: transparent;
//  }
//  &:hover{
//    color: $baseWhite;
//    background-color: $medBlue;
//  }
//}
//
//.clubster-button {
//  width: 100%;
//}

//.clubster-alt-button,
//.clubster-danger-button,
//.clubster-admin-button {
//  &.small {
//    padding: $spacing-small;
//    padding-left: $spacing-medium;
//    padding-right: $spacing-medium;
//    font-size: 14px;
//    line-height: 14px;
//  }
//}
//
//


button[disabled],
button.disabled,
input[type=submit][disabled],
input[type=button][disabled],
input[type=reset][disabled],
input[type=checkbox][disabled],
.button[disabled],
.clubster-button[disabled],
.clubster-alt-button[disabled],
.clubster-admin-button[disabled],
.clubster-danger-button[disabled] {
  cursor: default !important;
  transition: none !important;
  background-color: #7e7e7e !important;
  color: lightgray !important;
  border-color: lightgray !important;
}


.split-button-group {
  @include flex-row();
  button,
  input[type=submit],
  input[type=button],
  input[type=reset],
  .button,
  .clubster-button,
  .clubster-alt-button,
  .clubster-admin-button,
  .clubster-danger-button {
/*
    &:first-of-type{
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &:last-of-type{
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
*/
    flex: 1;
    /* border-radius: 0; */
    margin-right:1px;
  }
}

.single-input-style {
  input {
    text-align: center;
    font-size: 18px;
    padding: 9px;
    border: dashed 3px #325c83;
    border-radius: 6px;
    width: 100%;
    font-weight: bold;
    color: #325c83;
  }
}


//[type="checkbox"]:checked, [type="radio"]:checked {
//  border-color: transparent;
//  background-color: currentColor;
//  background-size: 100% 100%;
//  background-position: center;
//  background-repeat: no-repeat;
//}
//
//[type="checkbox"]:checked {
//  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
//}
//
//[type="radio"]:checked {
//  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
//}
