/*! purgecss start ignore */
@import 'includes/reset';
@import 'includes/global_settings';

@import "../components/topbar/topbar";
@import "includes/cover";
@import "../components/wayfarer/component";
@import "../components/media-viewer/media-viewer";
@import "includes/subscription";
@import "includes/feed";
@import "includes/post";
@import "includes/event";
@import "includes/form";
@import "includes/navigation/floating";
@import "includes/organization";
@import "includes/breadcrumbs";
@import "includes/product_table";
@import "includes/cards";
@import "includes/organization/admin";
@import "includes/navigation/menu";
@import "includes/clubster_font";
@import "includes/lozenges";
@import "includes/table";
@import "includes/autocomplete";
@import "includes/statistics";

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: -3px;
  background-color: $baseColor;
  color: $baseBlack;
  @include font-opensans;

  &>div {
    align-self:stretch;
  }

  .password-reset-toggle {
    float: right;
    margin-right: 0.75rem;
    position: relative;
    z-index: 2;
  }

  .password-reset-toggle-first {
    margin-top: -3.0rem;;
  }

  .password-reset-toggle-second {
    margin-top: -2.0rem;;
  }

  .password-change-toggle {
    float: right;
    margin-right: 0.75rem;
    margin-top: -1.75rem;;
    position: relative;
    z-index: 2;
  }

  .masthead {
    max-width: 1280px;
    width: 100%;
    margin:auto;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .logo {
    background: url(../images/logo/logo.svg) no-repeat;
  }

  .warning-sign {
    background: url(../images/warning-sign.png) no-repeat;
  }

  .qrcodes {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    .apple-qrcode {
      width: 200px;
      height: 200px;
      background: url(../images/apple_appstore-qrcode-200x200.png) no-repeat;
    }

    .google-qrcode {
      width: 200px;
      height: 200px;
      background: url(../images/google_play-qrcode-200x200.png) no-repeat;
    }
  }

  .font-raleway {
    @include font-raleway;
  }
  .font-opensans {
    @include font-opensans;
  }

  main {
    justify-self: center;
    flex: 1 0 auto;
    max-width: $baseMaxWidth;
    //width: $baseMaxWidth;
    padding-top: 24px;
    @include flex-row(flex-start, stretch);

    .content-box {
      //background: #ffffff;
      //box-shadow: 0 1px 4px rgba($baseBlack,.24);
      //border-radius: 3px;
      //padding: 16px;
      //margin-bottom: 32px;

      h1 {
        font-weight: 700;
        @include font-raleway;
        font-size: 16px;
        margin-bottom: 8px;
      }
    }
  }

  .left-col,
  #left-col {
    order: 0;
    flex: 1;
    max-width: 25%;
    margin-right: 16px;
  }

  .right-col,
  #right-col {
    order: 1;
    flex: 2.5;
    margin-left: 16px;
    max-width: 75%;
  }

  .full-col,
  #full-col {
    flex-grow: 1;
    width: 100%;
  }

  .row {
    @include flex-row(flex-start, stretch);


    &.horizontal-center {
      align-items: center;
    }
  }

  .stretch {
    flex: 1;
  }
  .no-stretch {
    flex: 0;
  }
  .flex-right {
    margin-left: auto;
  }

  .column {
    @include flex-column(flex-start, stretch);

    .stretch {
      flex: 1;
    }
    .no-stretch {
      flex: 0;
    }
  }

  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
  }

  footer {
    max-width: $baseMaxWidth;
    width: 100%;
    color: $baseBlack;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-items: flex-start;
    margin-top: $spacing-large;
    margin-bottom: $spacing-large;

    a {
      text-decoration: none;
      color: $medBlue;
    }

    //flex: none;
    //display: flex;
    //justify-content: stretch;
    //align-items: stretch;
    //
    #footer-left {
      order:0;
      text-align: left;
      flex:1;
    }
    #footer-center {
      order:1;
      text-align: center;
      flex:1;
    }
    #footer-right {
      order:2;
      text-align: right;
      flex:1;
    }

    &.mobile {
      flex-direction: column;
      align-items: center;

      #footer-left {
        text-align: center;
        margin-bottom: $spacing-small;
        width: 100%;
      }
      #footer-right {
        text-align: center;
      }
    }
  }
}

body[data-route="login"],
body[data-route="register"],
body[data-route="invitation:decline-mobile"],
body[data-route="verification-required"],
body[data-route="index::about"],
body[data-route="index::contact"],
body[data-route="index::pricing"],
body[data-route="index::features"],
body[data-route="index::support"],
body[data-route="index:mobile"],
body[data-route="anonIndex"],
body[data-route="verify"],
body[data-route="complete-registration"],
body[data-route="verification-required"],
body[data-route^="club:create"],
body[data-route="forgotPassword"],
body[data-route="resetSent"],
body[data-route="resetPassword"]
{
  background: url(../images/mute_geo_bg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.danger-text {
  color: $darkRed;
}

.muted {
  font-size: 12px;
  color: rgba($baseBlack, 0.6);
}

.muted-link {
  color: $baseBlue;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}

//.badge {
//  box-sizing: content-box;
//  position: absolute;
//  top:-3px;
//  right: 48px;
//  background: #e40000;
//  min-width: 24px;
//  height: 24px;
//  line-height: 23px;
//  text-align: center;
//  color: #ffffff;
//  font-weight: 600;
//  border-radius: 24px;
//  padding: 2px;
//}

strong {
  font-weight: 700;
}

.ajax-spinner {
  margin: 0px auto;
  height: 40px;
  text-align: center;
  font-size: 10px;
  opacity: 1;
  transition: opacity 100ms;

  &.dark {
    > div {
      background-color: $baseBlue !important;
    }
  }

  > div {
    background-color: #fff;
    height: 100%;
    width: 8px;
    display: inline-block;
    margin-left: 1px;
    margin-right: 1px;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  @-webkit-keyframes sk-stretchdelay {
    0% {
      -webkit-transform: scaleY(0.4);
      opacity: 0;
    }
    40%, 100% {
      -webkit-transform: scaleY(0.4);
      opacity: 0.7;
    }
    20% {
      -webkit-transform: scaleY(1.0);
      opacity: 1;
    }
  }

  @keyframes sk-stretchdelay {
    0% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
      opacity: 0;
    }
    40%, 100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
      opacity: 0.7;
    }
    20% {
      transform: scaleY(1.0);
      -webkit-transform: scaleY(1.0);
      opacity: 1;
    }
  }
}


#post-form {
  .form-section.post_form_main_section,
  #post_subject-container,
  #event_subject-container,
  #post_body-container ,
  #event_body-container {
    width: 100%;
    border-left: solid 1px $baseBlue;
    border-right: solid 1px $baseBlue;

    #post_subject,
    #event_subject,
    #post_body ,
    #event_body {
      box-sizing: border-box;
      width: 100%;
      border: none;
      padding: 8px;
      @include font-opensans;
    }

    #post_subject,
    #event_subject {
      border-top: solid 1px $baseBlue;
      border-bottom: solid 1px $medBlue;
      &:focus {
        border-color: #66AFE9;
        outline: 0;
        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
      }
    }

    #post_body ,
    #event_body {
      overflow: hidden;
      &:focus {
        border-color: #66AFE9;
        outline: 0;
        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
      }
    }

  .post-form-errors {
    background-color: #f2dede;
    padding: 8px;
    margin: 8px;
    border-radius: 6px;
    border: solid 1px darkred;
    color: darkred;
  }

  #post-form-options {
    width: 100%;
    border-left: solid 1px $baseBlue;
    border-right: solid 1px $baseBlue;
    border-bottom: solid 1px $baseBlue;
    margin-bottom: 16px;

    #post-form-options-toolbar {
      background-color: $medBlue;
      border-top: solid 1px $medBlue;
      border-bottom: solid 1px $medBlue;

      ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: nowrap;

        li {
          flex-basis: auto;
          min-width: 32px;
          height: 32px;
          color: $baseWhite;
          padding: 8px;
          box-sizing: border-box;
          border-right: solid 1px $baseWhite;
          cursor: pointer;
          transition: color 175ms, background 175ms;
          text-align: center;

          &.active,
          &:hover {
            background: $baseWhite;
            color: $baseBlue;
          }
        }
      }
    }
    .post-form-option-container{
      display: none;
      border-bottom: dashed 1px $baseBlue;

      &.shown {
        display: block;
      }

      &.last {
        border-bottom: none;
      }
    }
    #post_options_media-drop_container ,
    #event_options_media-drop_container {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      min-height: 100px;
      border: dashed 3px $medBlue;
      background: $baseWhite;

      &.is-drag {
        #post_options_media-drop_preview ,
        #event_options_media-drop_preview {
          display: block;
        }
        .child-elements {
          pointer-events: none;
        }
      }

      .post_options_media-file_preview {
        display: flex;
        background-size: contain;
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        z-index: 1;
        background-repeat: no-repeat;
        background-position: center center;

        video {
          max-width: 100%;
          max-height: 100%;
        }
      }

      #post_options_media-drop_preview ,
      #event_options_media-drop_preview {
        display: none;
      }

      #post_options_media-drop_preview,
      #event_options_media-drop_preview,
      .post_options_media-file_container {
        height: 128px;
        width: 128px;
        flex-basis: 128px;
        border: dashed 4px $medBlue;
        border-radius: 6px;
        margin: 16px;
        box-sizing: content-box;
        background: $lightBlue;
        position: relative;

        &::before {
          display: block;
          position: absolute;
          content: "\f067";
          font-family: "Font Awesome 5 Free";
          color: $medBlue;
          font-size: 36px;
          font-weight: 700;
          top: 32px;
          margin: auto;
          width: 100%;
          text-align: center;
          height: 64px;
          line-height: 64px;
        }

        .post_options_media-file_remove {
          display:none;
        }

        &:hover {
          .post_options_media-file_remove {
            display: block;
            position: absolute;
            font-family: "Font Awesome 5 Free";
            color: $baseBlue;
            font-size: 16px;
            top: 4px;
            right: 4px;
            text-align: center;
            height: 16px;
            line-height: 16px;
            width: 16px;
            z-index: 2;
            cursor: pointer;
          }
        }

        .post_options_media-file_input {
          display: none;
        }
      }

      &.has-files {
        #post_options_media-drop_instructions ,
#event_options_media-drop_instructions {
          display: none;
        }
      }

      #post_options_media-drop_instructions ,
#event_options_media-drop_instructions {
        height: 36px;
        width: 232px;
        text-align: center;
        font-size: 18px;
        margin: auto;
        position: absolute;
        top: 0; left: 0; bottom: 0; right: 0;
        color: $medBlue;
      }
    }
  }
}

.hidden {
  display:none;
}

.dotted {
  border-bottom: dotted 1px $medBlue;
}

.row {
  .row-avatar {
    display: inline-block;
    //background: url(../images/logo/logo.svg) no-repeat;
    border-radius: 200px;
    margin: 4px;
    height: 32px;
    width: 32px;
    flex-basis: 32px;
    overflow: hidden;
  }

  .row-title {
    margin-top: 12px;
    margin-left: 12px;
  }

  &.horizontal-center {
    align-items: center;
  }
}

.avatar-arrow {
  font-size: 32px;
  margin-top: 4px;
  color: $baseBlue;
}

.mtr-input-slider-hidden {
  display:none !important;
}

.section-container {
  position: relative;
  border: dashed 1px #95b5d4;
  border-radius: 3px;
  margin-bottom: $spacing-large;
  background-color: $lightBlue;
  transition: background-color 100ms;
  padding: 12px 12px;

  .section-title {
    //position: absolute;
    top: 8px;
    left: 8px;
    font-size: 24px;
    font-weight: bold;
    color: #95b5d4;
    z-index: 1;
    margin-bottom: 8px;
  }
}

.groupinfo {
  padding: 6px;
  background: #ffffff;
  transition: background 250ms;
  border-radius: 4px;
  color: $baseBlack;
  text-decoration: none;
  align-items: center !important;

  .club-avatar {
    display: inline-block;
    //background: url(../../images/logo/logo.svg) no-repeat;
    border-radius: 200px;
    margin: 4px;
    marrgin-left: 0px;
    height: 32px;
    width: 32px;
    flex-basis: 32px;
    overflow: hidden;
  }
  .groupinfo-avatar {
    display: inline-block;
    //background: url(../../images/logo/logo.svg) no-repeat;
    border-radius: 200px;
    margin: 4px;
    marrgin-left: 0px;
    height: 32px;
    width: 32px;
    flex-basis: 32px;
    overflow: hidden;
  }

  >.avatar:first-of-type {
    margin-left: 0px;
  }

  >.avatar:nth-of-type(2) {
    margin-left: -16px;
  }

  .groupinfo-title {
    max-width: 208px;
    align-self: center;
    margin-left: auto;

    &.twoLines {
    }
  }

  .groupinfo-title-left {
    align-self: center;
    margin-left: 12px;

    &.twoLines {
    }
  }

  .groupinfo-name-truncate {
    overflow: hidden;
    max-width: 20ch;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .groupinfo-parentname-truncate {
    overflow: hidden;
    max-width: 30ch;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .subtext{
    font-size: 12px;
  }


  &:hover {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d4eaff+0,ffffff+100&1+0,0+100 */
    background: -moz-linear-gradient(-45deg, rgba(212,234,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(212,234,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(212,234,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4eaff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }
}


#feed {
  position: relative;

  #feed-end {
    position: absolute;
    bottom: 500px;
    left: 0;
  }
}

.context-item {
  border: solid transparent 1px;
  border-radius: 3px;
  max-height: 18px;
  max-width: 18px;
  text-align: center;
  padding: 4px;
  width: 18px;
  font-size: 18px;
  position: relative;
  color: $medBlue;
}

.context-btn {
  .context-menu {
    display: none;
    width: 180px;
    position: absolute;
    top: 9px;
    right: 9px;
    background: $baseWhite;
    border: solid rgba($baseBlack, 0.24) 1px;
    text-align: left;
    padding: 12px;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba($baseBlack, 0.24);
    font-size: 16px;
    z-index: 77;

    .context-menu-item {
      padding: $spacing-medium;
      color: $baseBlack;
      cursor: pointer;
      border: solid $baseWhite 1px;
      border-radius: 3px;
      transition: border linear 100ms;
      a {
        text-decoration: none;
        color: $baseBlack;
      }
      &:hover {
        border: solid $medBlue 1px;
      }
    }
  }

  &:hover {
    cursor: pointer;
    border: solid $medBlue 1px;

    .context-menu {
      display: block;
    }
  }
}

.headline {
    @include font-raleway();
    font-size: 24px;
    font-weight: 700;

    &.sub-headline {
        font-size: 18px;
    }
}

.error-block {
  color: $darkRed !important;
  border-color: $darkRed !important;
  background-color: #ffecec;
  padding: 16px;
  text-align: center;
  box-shadow: 0 1px 4px rgba($baseBlack,.24);
}

.notice-block {
  background: #fff8ba;
  padding: 16px;
  color: #827825;
  text-align: center;
  box-shadow: 0 1px 4px rgba($baseBlack,.24);
}

.info-block {
  box-shadow: 0 1px 4px rgba($baseBlack,.24);
  background: #ebf6ff;
  padding: 16px;
  color: #4e94b7;
  text-align: center;
  border: solid 1px #95b5d4;
}

.content-box {
  .notice-block,
  .info-block {
    box-shadow: none !important;
  }
}

.feedLoading {
  text-align: center;
  color: #95b5d4;
}

.clean-link {
  text-decoration: none;
  color: inherit;
}

.page-separator {
  margin-bottom: 16px;
  color: #95b5d4;
  border-bottom: solid 1px
  #95b5d4;
  padding-bottom: 8px;
  margin-top: -16px;
}

.event-attending {
  @apply bg-green-700;
  @apply border-green-700;
  @apply text-white;
}

.event-onwaitlist {
  @apply bg-yellow-700;
  @apply border-yellow-700;
  @apply text-white;
}

tr.event-onwaitlist:hover,
tr.event-attending:hover {
  color: initial;
}

tr.event-attending:hover td {
  @apply bg-green-500 #{!important};
}

tr.event-onwaitlist:hover td {
  @apply bg-yellow-500 #{!important};
}

#mobile-call-out {
  background: rgba(0,0,0,.8);
  color: #fff;
  /* height: 25vh; */
  font-size: 18px;
  font-weight: bold;
  border-top: #000 solid 3px;
  padding: 12px;
  text-align: center;
  line-height: 39px;
}
.sl-avatar::part(base) {
  background: #95b5d4;
}
.sl-avatar::part(initials) {
  top: calc(var(--size)*-.05);
  position: relative;
}

.notifications-sl-badge{
  position: absolute;
  top: -8px;
  right: 32px;
}
.notifications-sl-badge::part(base) {
  font-size: 18px;
  padding: 8px;
  height: 28px;
  width: 28px;
}

.wait-for-submit {
  .wait-for-submit-spinner {
    display:none;
  }
  &.disabled {
    .wait-for-submit-spinner {
      display:inline-block;
    }
  }
}

[x-cloak] {
  display: none;
}

//.post-list-items {
//  .status-pending {
//      color: #90902a;
//    }
//
//  .status-published {
//   //color: #82c91e;
//  }
//
//  .status-cancelled,
//  .status-deleted {
//    color: #e12914;
//  }
//
//  &.status-revoked {
//  }
//}

/*! purgecss end ignore */
