.easy-autocomplete-container #autocomplete-loader {
  padding: 6px !important;
  background: $baseBlue;
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  box-shadow: 0 1px 4px rgba($baseBlack, 0.24) !important;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  transition: background-color 175ms linear;
  &.danger {
    background-color: $darkRed !important;
    border: solid 1px $darkRed;
  }
}
.easy-autocomplete-container #autocomplete-loader,
.easy-autocomplete-container ul {
  padding: 6px !important;
  background: $baseBlue !important;
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  box-shadow: 0 1px 4px rgba($baseBlack, 0.24) !important;

  li {
    background: $baseWhite !important;
    &:not(:last-of-type) {
      margin-bottom: 6px !important;
    }

    &.selected {
      background: $lightBlue !important;
    }

    .autocomplete-result {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      padding: 6px;

      .autocomplete-title,
      .autocomplete-detail {
        flex: 1;
      }
    }
  }
}