$baseColor: #fafafa;
$baseBlue: #325c84;
$medBlue: #95b5d4;
$lightBlue: #eff4f9;
$baseWhite: #ffffff;
$baseBlack: #1a1a1a;
$darkRed: darkRed;
$medRed: #b10000;
$baseMaxWidth: 1280px;

$fa-font-path: '../../../webfonts';

@import "../includes/fontawesome/fontawesome.scss";
@import "../includes/fontawesome/solid.scss";
@import "../includes/fontawesome/brands.scss";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Raleway:400,700');

@mixin font-raleway {
  font-family: 'Raleway', sans-serif;
}

@mixin font-opensans {
  font-family: 'Open Sans', sans-serif;
}

@mixin flex-row($alignment: center, $justify: space-between, $wrap: nowrap) {
  display: flex;
  flex-direction: row;
  flex-wrap: $wrap;
  justify-content: $justify;
  align-items: $alignment;
}

@mixin flex-column($alignment: center, $justify: space-between, $wrap: nowrap) {
  display: flex;
  flex-direction: column;
  flex-wrap: $wrap;
  justify-content: $justify;
  align-items: $alignment;
}

@mixin column() {
  flex: 1;
}

@mixin avatar($avatarSize) {
  display: inline-block;
  //background: url(../../images/logo/logo.svg) no-repeat;
  border-radius: 200px;
  margin: 4px;
  height: $avatarSize;
  width: $avatarSize;
  flex-basis: $avatarSize;
  overflow: hidden;
  //box-shadow: 0px 0px 4px rgba(26, 26, 26, 0.3);
  img,
  div {
    height: $avatarSize;
    width: $avatarSize;
  }
}

@mixin expandableTextInput($width, $minHeight, $maxHeight) {
  width: $width;
  min-height: $minHeight;
  max-height: $maxHeight;
  overflow-x: hidden;
  overflow-y: auto;
}

.logo-bg {
  background: url(../../images/logo/logo.svg) no-repeat;
}

.default-bg {

  background: url(../../images/mute_geo_cover.9af8f67a.jpg) no-repeat;
}

input,
textarea {
  @include font-opensans;
  font-size: 14px;
  font-weight: 400;
}

// spacing
$spacing-small: 6px;
$spacing-medium: 12px;
$spacing-large: 18px;
$spacing-huge: 32px;

$spacing: ("small" $spacing-small) ("medium" $spacing-medium) ("large" $spacing-large) ("huge" $spacing-huge);
$sides: ("top" "bottom" "left" "right");
$spaceTypes: ('padding' 'margin');
@each $spaceType in $spaceTypes {
  @each $name, $size in $spacing {
    .#{$spaceType}-#{$name} {
      #{$spaceType}: $size !important;
    }

    @each $side in $sides {
      .#{$spaceType}-#{$side}-#{$name} {
        #{$spaceType}-#{$side}: $size !important;
      }
    }
  }
}

$coloring: ('light-blue' $lightBlue) ('medium-blue' $medBlue) ('base-blue' $baseBlue) ('white' $baseWhite);

@each $colorName, $color in $coloring {
  .background-#{$colorName} {
    background-color: $color;
  }

  .hover-#{$colorName} {
    transition: background-color 150ms !important;

    &:hover {
      background-color: $color;
    }
  }

}

.section {
  //&:not(:first-of-type) {
    border-top: dashed 1px $medBlue;
  //}

  position: relative;
  background-color: $lightBlue;
  transition: background-color 100ms;
  padding: 12px 12px;

  .section-title {
    top: 8px;
    left: 8px;
    font-size: 24px;
    font-weight: bold;
    color: #95b5d4;
    z-index: 1;
  }

  .section-content {
    border: dashed 1px $baseBlue;
    background: #ffffff;
    color: $baseBlue;
    padding: $spacing-small;
  }
}
