.card-container,
.stat-panel-container {
  display: flex;
  flex-wrap: wrap;

  .card,
  .stat-panel {
    flex: 1 0 31.5%;
    position: relative;
    max-width: 31.5%;
    border-radius: 6px;
    background-color: $medBlue;
    text-align: center;
    padding: 32px;
    box-sizing: border-box;
    color: #ffffff;
    box-shadow: 0 1px 4px rgba($baseBlack,.24);
    margin:8px;

    .card-title {
      font-weight: bold;
      font-size: 20px;
      border-bottom: solid 1px white;
      margin-bottom: 6px;
      padding-bottom: -1px;
      margin-top: -8px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .card-detail {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .stat-number {
      height: 75px;
      display: flex;
      margin-top: -16px;
      justify-content: center;
    }

    .stat-number-val {
      fill: #ffffff;
      font-weight: bold;
      flex: 0;
      align-self: center;
    }

    .stat-name {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: $lightBlue;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      padding: 8px;
      font-size: 18px;
      font-weight: bold;
      color: $baseBlue;
    }
  }
}
