.breadcrumb-content-box {
  background: #ffffff;
  box-shadow: 0 1px 4px rgba($baseBlack,.24);
  border-radius: 3px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  &.small {
    background-color: rgba(149, 181, 212, 0.22)
  }
  @supports (-ms-ime-align:auto) {
    .breadcrumb {
      border-right: solid 1px  $baseBlue;
      &:hover {
        border-right: solid 1px $baseWhite;
      }
    }
  }
  .breadcrumb {
    color: $baseBlack;
    text-decoration: none;
    display:block;
    cursor: pointer;
    padding: 16px;
    padding-left: 32px;
    position: relative;
    background-color: #ffffff;
    transition: color 150ms, background-color 150ms;

    &:first-of-type {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &.completed {
      background-color: #a6aeb5;
      color: #7e7e7e;
      &::before {
        background-color: #a6aeb5;
        border: solid 1px  #7e7e7e;
      }
    }

    &.active,
    &:hover {
      background-color: $medBlue;
      color: $baseWhite;
      &::before {
        background-color: $medBlue;
        border: solid 1px  $baseWhite;
      }
    }

    &::before {
      display: block;
      width: 44px;
      height: 44px;
      position: absolute;
      background-color: white;
      border-top: solid 1px  $baseBlue;
      border-right: solid 1px  $baseBlue;
      content: ' ';
      transform: rotate(135deg);
      margin: auto;
      top: 1px;
      right: -14px;
      border-top-left-radius: 6px;
      -webkit-clip-path: polygon(24px 0, 0 24px, 0 0);
      //clip-path: polygon(76% 0, 0 76%, 0 0);
      clip-path: url("#clipPolygon");
      transition: color 150ms, background-color 150ms;
    }

    @supports (-ms-ime-align:auto) {
      &::before {
        display: none !important;
      }
    }

    &.small {
      padding: 4px;
      padding-left: 24px;

      &::before {
        width: 30px;
        height: 30px;
        right: -10px;
        z-index: 9;
      }
    }
  }

  h1 {
    font-weight: 700;
    @include font-raleway;
    font-size: 16px;
    margin-bottom: 8px;
  }
}
